import ScrollToTop from "../innerComponents/ScrollToTop"
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import Footer from '../innerComponents/Footer';
import Whatsapp from '../innerComponents/Whatsapp';
import About from './About';
import Home from './Home'
import Services from './Services';
import Contact from './Contact';
import AllServices from '../innerComponents/AllServices';
import Error from './Error';
import { services } from '../data/services';
import { portfolios } from '../data/portfolios';
import ServiceDetails from '../innerComponents/ServiceDetails';
import ScrollTopButton from '../innerComponents/ScrollTopButton';
import Portfolio from './Portfolio';
import JoinUs from './JoinUs';
import ImagePortfolio from "../innerComponents/ImagePortfolio";
import VideoPortfolio from "../innerComponents/VideoPortfolio";
import PricePlan from "./PricePlan";
import PriceSubmit from "./PriceSubmit";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Refund from "./Refund";
import SearchNotFound from "../innerComponents/SearchNotFound";
 
function App() {
  return (
    <>
      <ScrollToTop/>
      <Whatsapp/>
      <ScrollTopButton/>
      <AnimatePresence >
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path="/services" element={<Services />}>
              <Route path="" element={<AllServices services={services} category="all" />} />
              {services.map((item, index) => (
                <Route key={index} path={item.category} element={<AllServices services={services} category={item.category} />} />
              ))}
              {services.map((item, index) => (
                <Route key={index} path={`${item.category}/${item.sroute}`} element={<ServiceDetails service={item} />} />
              ))} 
            </Route>
            <Route path="*" element={<Error/>} />
            <Route path='/portfolio/images' element={<ImagePortfolio portfolios={portfolios}/>}></Route>
            <Route path='/portfolio/videos' element={<VideoPortfolio/>}></Route>
            <Route path='/portfolio' element={<Portfolio portfolios={portfolios}/>}></Route>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/joinus' element={<JoinUs/>}/>
            <Route path='/pricing-plan' element={<PricePlan/>}/>
            <Route path='/price-submit' element={<PriceSubmit/>}/>
            <Route path='/terms-and-conditions' element={<Terms/>}/>
            <Route path='/privacy-policy' element={<Privacy/>}/>
            <Route path='/cancellation-and-refund-policy' element={<Refund/>}/>
            <Route path='/search' element={<SearchNotFound/>}/>
        </Routes>
      </AnimatePresence>
      <Footer/> 
    </>
  );
} 

export default App;
