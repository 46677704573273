import CreditSection from '../innerComponents/Credit';
import GetInTouch from '../innerComponents/Getintouch';
import HomeSection1 from '../innerComponents/HomeSection1';
import HomeSection2 from '../innerComponents/HomeSection2';
import HomeSection22 from '../innerComponents/HomeSection22';
import Navbar from '../innerComponents/Navbar';
import PortfolioSection from '../innerComponents/PortfolioSection';
import ServiceSlider from '../innerComponents/ServiceSlider';
import ServiceSlider2 from '../innerComponents/ServiceSlider2';
import '../styles/Home.css';

function Home(){
    return(
        <>
            <div className='home'>
                {/* <div style={{backgroundColor:'#000333'}}> */}
                    <Navbar/>
                {/* </div> */}
                <HomeSection1/>
                <HomeSection22/> 
                <CreditSection/>             
                <PortfolioSection/>
                <div style={{margin:"50px 0"}}></div>
                <ServiceSlider/>
                <ServiceSlider2/>
                <div style={{margin:"50px 0"}}></div>
                <HomeSection2/>
                <GetInTouch/>
            </div>
        </>
    )
}


export default Home;