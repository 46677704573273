import React, { useState } from 'react';
import "../styles/PortfolioSwiper.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import arr from '../assets/arrow_infobox.png';
import { FaTimes } from 'react-icons/fa';

export default function PortfolioSwiper({portfolios}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleCardClick = (img) => {
    setSelectedImage(img);
  };
  return (
    <div className="swiper">
      {selectedImage && (
        <div className="popmain">
           <div className='popupimg'>
                <img src={selectedImage} alt='portfolio'></img>
                <div className='span' onClick={() => setSelectedImage(null)}><FaTimes/></div>
           </div>
        </div>
      )}
      <Carousel showDots={true} responsive={responsive}>
      {portfolios.map((item, index) => (
        <div key={index} className="card" onClick={() => handleCardClick(item)}>
          <div className="img"><img src={item} alt="portfolio"/></div>
          <div className='arrow'><img src={arr} alt='service'/></div>
        </div>
      ))} 
      </Carousel>
    </div>
  );
}