import Navbar from '../innerComponents/Navbar';
import '../styles/JoinUs.css'
import React from 'react';

function PriceSubmit(){
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "617f87ff-6101-473c-bcac-1d8c02d184a6");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          event.target.reset();
        } else {
          console.log("Error", data);
        }
      };
    return(
        <>
            <div className='body'>
                <Navbar/>
                <div className='joinuscontainer'>
                    <div className="row">
                        <section className="col right">
                            <form onSubmit={onSubmit} className="messageForm">
                                <div className="inputGroup fullWidth">
                                    <span className='drop'>Enroll Now</span>
                                </div>
                                <div className="inputGroup fullWidth" style={{display:'none'}}>
                                    <input type="text" name="Credit" value='Credit'/>
                                    <label>Credit</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="text" name="Name" required="required"/>
                                    <label>Name</label>
                                </div>
                                <div className="inputGroup fullWidth" style={{display:'none'}}>
                                    <input type="email" name=""/>
                                    <label>None</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="text" name="Email" required="required"/>
                                    <label>Email</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="text" name="Place" required="required"/>
                                    <label>Place</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="text" name="Phone" required="required"/>
                                    <label>Contact Number</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="text" id="position" list="positions" name='CreditAmount' required="required"/>
                                    <datalist id="positions">
                                        <option value="7670"/>
                                        <option value="14340"/>
                                        <option value="20060"/>
                                        <option value="29500"/>
                                        <option value="59000"/>
                                    </datalist>
                                    <label for='position'>Select Plan</label>
                                </div>
                                <button type='submit' className='button'>
                                    <div className="button1"></div><div className="text">Enroll Now →</div>
                                </button>
                            </form>
                        </section>                           
                    </div>
                </div>
            </div>   
        </>
    )
}
export default PriceSubmit;