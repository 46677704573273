import { Link } from "react-router-dom";
import GetInTouch from "./Getintouch";
import '../styles/Services.css';

function ServiceDetails({service}){
    const about = service.about.split('\n');
    return(
        <>
            <div className="service-card">
                <div className="heading">{service.name}</div>
                <div className="details">
                    <div className="head">
                        <span className="quote">{service.about_head}</span>
                        <img src={service.image} alt='logo' />
                    </div>
                    {about.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
                {service.name==="Credit Health Improvement"?
                    <>
                        <Link to="/pricing-plan" key='1' className='button'>
                            <div className="button1"></div><div className="text">Choose Your Pricing Plan →</div>
                        </Link>                    
                    </>
                    :
                    <></>
                }
            </div>
            <GetInTouch/>
        </>
    )
}

export default ServiceDetails;