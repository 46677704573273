import Navbar from "../innerComponents/Navbar";
import "../styles/Error.css";
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { searches } from '../data/search';
import { FaSearch } from "react-icons/fa";
import { useState } from "react";

const SearchNotFound = () => {
    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search).get('q');
	const filteredSearches = searches.filter((item) =>
		item.description.toLowerCase().includes(searchQuery.toLowerCase())
	);	
    const [searchQuery1, setSearchQuery] = useState('');
	const navigate = useNavigate();

	const handleSearch = () => {
		if(searchQuery1.trim() !== '') {		
			navigate(`/search?q=${encodeURIComponent(searchQuery1)}`);
		}	
	};

	const handleChange = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
		handleSearch();
		}
	};
    return (
        <>           
            <div className="searchmain">
                <Navbar/>
                <span className="heading">Search</span>
                <span className="subhead">Search Result for : {searchQuery}</span>
                {filteredSearches && filteredSearches.length > 0 ?
                    <>
                        {filteredSearches.map((item, index) => (
                        <div className="card" key={index}>
                            <span className="head">{item.name}</span>
                            <span className="txt">{item.description}</span>
                            <Link className="button" to={item.route}><span>+</span>Read More</Link>
                        </div>
                        ))}
                    </>:
                    <>
                        <div className="nothing">
                            <span className="head">Nothing Found</span>
                            <span className="txt">Sorry, but nothing matched your search terms. Please try again with some different keywords.</span>
                            <div className={`search-bar`}>
                                <input type="text" placeholder="Search Services..." value={searchQuery1} onChange={handleChange} onKeyDown={handleKeyPress}/>
                                <FaSearch style={{margin:'0 10px'}} onClick={handleSearch}/>
                            </div>
                            <Link to="/" key='1' className='button'>
                                <div className="button1"></div><div className="text">Go Back to Home →</div>
                            </Link>
                        </div>                       
                    </>
                }
            </div>
        </>

    )
};

export default SearchNotFound;