import '../styles/ScrollTopButton.css';
import { useState } from "react";
import { MdArrowUpward } from 'react-icons/md';


function ScrollTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;

        if (scrollTop > 2) { 
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    window.addEventListener("scroll", handleScroll);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div className={`scroll-top-button ${isVisible ? 'show' : ''}`}>
            <div className='btn' onClick={scrollToTop}><MdArrowUpward/></div>
        </div>
    );
}

export default ScrollTopButton;