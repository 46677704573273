import '../styles/HomeSection22.css';
import web from '../assets/servicelogo/webapp.png';
import seo from '../assets/servicelogo/seo.png';
import arr from '../assets/arrow_infobox.png';
import branding from '../assets/servicelogo/branding.png';
import { Link } from 'react-router-dom';

function HomeSection22(){
    return(
        <>
            <div className='home-section22'>
                <div className="sec3">
                    <div className="quote1"><span>Your Business Thrives When It's Digital.</span></div>
                </div>
                <div className="sec3">
                    <div className="quote2"><span>Without an Online Presence, You're Not Fully Marketing.</span></div>
                </div>
                <div className="sec1">
                    <div className="quote"></div>
                    <div className="servicebutton">
                        <Link to="/services" key='1' className='button'>
                            <div className="button1"></div><div className="text">VIEW ALL SERVICES →</div>
                        </Link>
                    </div>
                </div>
                <div className="sec2">
                    <div className="image"> 
                        <div className="imgcontainer">
                            <img className='img' src={web} alt='service'/>
                            <Link to='/services/virtual-expertise/web-and-app-development' className='arrow'><img src={arr} alt='service'/></Link>
                        </div>
                        <div className="title"><span>website development</span></div>             
                    </div>
                    <div className="image">
                        <div className="imgcontainer">
                            <img className='img' src={seo} alt='service'/>
                            <Link to='/services/virtual-expertise/seo-and-sem' className='arrow'><img src={arr} alt='service'/></Link>
                        </div>
                        <div className="title"><span>seo&sem</span></div>
                    </div>
                    <div className="image">
                        <div className="imgcontainer">
                            <img className='img' src={branding} alt='service'/>
                            <Link to='/services/virtual-expertise/branding' className='arrow'><img src={arr} alt='service'/></Link>
                        </div>
                        <div className="title"><span>branding</span></div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default HomeSection22;