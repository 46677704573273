import { useRef,useState,useEffect } from "react";
import { FaBars, FaTimes,FaSearch } from "react-icons/fa";
import { Link, NavLink,useLocation,useNavigate } from "react-router-dom";
import "../styles/Nav.css";
import logo from '../assets/logo.png';

function Navbar() {
	const [searchQuery, setSearchQuery] = useState('');
	const navigate = useNavigate();

	const handleSearch = () => {
		if(searchQuery.trim() !== '') {		
			navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
		}	
	};

	const handleChange = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
		handleSearch();
		}
	};

	const navRef = useRef();
	const location = useLocation();
	const [activeLinks, setActiveLinks] = useState(["/"]);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
	window.addEventListener('resize', () => {
		setIsMobile(window.innerWidth <= 768);
	});
	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};
	useEffect(() => {
        const currentPath = location.pathname;
        setActiveLinks([currentPath]);
    }, [location]);

	const [open,setOpen]=useState(false);
	const toggleOpen = () => {
		if(openPortfolio){
			setOpenPortfolio(!openPortfolio)
		}
        setOpen(!open);
    }
	const [openPortfolio,setOpenPortfolio] = useState(false);
	const toggleOpenPortfolio = () => {

		if(open){
			setOpen(!open)

		}
		setOpenPortfolio(!openPortfolio);
	}
	const [openSearch,setOpenSearch] = useState(false);
	const toggleOpenSarch = ()=>{
		setOpenSearch(!openSearch)
	}

	
	return (
		<header>		
			<Link className="img" to='/'><img src={logo} alt="Logo" /></Link>			
			<nav ref={navRef}> 
				<Link to='/' onClick={showNavbar} className="responsive_logo"><img src={logo} alt="Logo" /></Link>
				<div onClick={showNavbar}>				
					<NavLink to='/' className={activeLinks.includes("/")  ? "link active" : "link"}><div className="round"></div><span>Home</span></NavLink>
				</div>
				<div onClick={showNavbar}>
					<NavLink to="/about" className={activeLinks.includes("/about") ? "link active" : "link"}><div className="round"></div><span>About</span></NavLink>
				</div>
				<div className="dropdown" onClick={isMobile?toggleOpen:null} onMouseEnter={!isMobile?toggleOpen:null} onMouseLeave={!isMobile?toggleOpen:null}>
					<Link to={!isMobile?"/services":''} className={activeLinks.includes("/services") ? "link active" : "link"}><div className="round"></div><span>Services ⌵</span></Link>
					<div className={`dropdown-menu ${open?'active':'inactive'}`}>
						<div className="list">
							<NavLink onClick={showNavbar} className={activeLinks.includes("/services/virtual-expertise") ? "link active" : "link"} to="/services/virtual-expertise" key='1'><span>Virtual Expertise</span></NavLink>
							<NavLink onClick={showNavbar} className={activeLinks.includes('/services/preferred-solutions') ? "link active" : "link"} to="/services/preferred-solutions" key='2'><span>Preferred Solutions</span></NavLink>
							<NavLink onClick={showNavbar} className={activeLinks.includes('/services/credit-health-improvement') ? "link active" : "link"} to="/services/credit-health-improvement" key='3'><span>Credit Health Improvement</span></NavLink>
						</div>
					</div>
				</div>
				<div onClick={showNavbar}>
					<NavLink to="/joinus" className={activeLinks.includes("/joinus") ? "link active" : "link"}><div className="round"></div><span>JoinUs</span></NavLink>
				</div>
				<div className="dropdown" onClick={isMobile?toggleOpenPortfolio:null} onMouseEnter={!isMobile?toggleOpenPortfolio:null} onMouseLeave={!isMobile?toggleOpenPortfolio:null}>
					<Link to={!isMobile?"/portfolio":''} className={activeLinks.includes("/portfolio") ? "link active" : "link"}><div className="round"></div><span>Portfolio ⌵</span></Link>
					<div className={`dropdown-menu ${openPortfolio?'active':'inactive'}`}>
						<div className="list">
							<NavLink onClick={showNavbar} className={activeLinks.includes("/portfolio/images") ? "link active" : "link"} to="/portfolio/images" key='1'><span>Image Portfolio</span></NavLink>
							<NavLink onClick={showNavbar} className={activeLinks.includes('/portfolio/videos') ? "link active" : "link"} to="/portfolio/videos" key='2'><span>Video Portfolio</span></NavLink>
						</div>
					</div>
				</div>				
				<div onClick={showNavbar}>
					<NavLink to="/contact" className={activeLinks.includes("/contact") ? "link active" : "link"}><div className="round"></div><span>Contact</span></NavLink>
				</div>				
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button className="search-btn">
				{!openSearch?<FaSearch onClick={toggleOpenSarch}/>:<FaTimes onClick={toggleOpenSarch}/>}				
				<div className={`search-bar ${openSearch?'active':'inactive'}`}>
					<input type="search" placeholder="Search Services..." value={searchQuery} onChange={handleChange} onKeyDown={handleKeyPress}/>
					<FaSearch style={{margin:'0 10px'}} onClick={handleSearch}/>
				</div>
			</button>		
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;