import React, { useEffect, useRef } from 'react';
import '../styles/ServiceSlider2.css';

const ServiceSlider2 = () => {
  const logosRef = useRef(null);

  useEffect(() => {
    const copy = logosRef.current.cloneNode(true);
    logosRef.current.parentNode.appendChild(copy);
  }, []);

  return (
    <div className="logos2">
      <div ref={logosRef} className="logos-slide2">
        <div className="logo-text2">Trademark Registration</div>
        <div className="logo-text2">_</div>
        <div className="logo-text2">Private Limited Company</div>
        <div className="logo-text2">_</div>
        <div className="logo-text2">Proprietorship firm</div>
        <div className="logo-text2">_</div>
        <div className="logo-text2">Limited Liability Partnership</div>
        <div className="logo-text2">_</div>
        <div className="logo-text2">GST Return Filing</div>
        <div className="logo-text2">_</div>
        <div className="logo-text2">Accounting & Bookkeeping</div>
        <div className="logo-text2">_</div>
      </div>
    </div>
  );
};

export default ServiceSlider2;