export const searches=[
    {
        name:"Credit Health Improvement",
        route:"/services/credit-health-improvement/credit-health-improvement",
        description:"“As experts in Credit Health Improvement, our mission is to guide individuals toward financial well-being. Led by certified Credit Experts, we analyze credit reports, identify issues, and develop personalized plans for improvement—going beyond scores to enhance overall credit health.”"
    },
    {
        name:"Web & App Development",
        route:"/services/virtual-expertise/web-and-app-development",
        description:"“Elevate your online preferred-solutionssence with our expert Web & App Development services. We craft tailored digital solutions, ensuring your business stands out in the ever-evolving digital landscape.”"
    },
    {
        name:"Branding",
        route:"/services/virtual-expertise/branding",
        description:"“Elevate your brand with our expert branding services. We craft compelling visual narratives, ensuring a distinct and memorable identity that resonates with your audience. Transform your brand essence today.”",
    },
    {
        name:"Digital Marketing",
        route:"/services/virtual-expertise/digital-marketing",
        description:"“Amplify your brand’s online preferred-solutionssence with our strategic Digital Marketing solutions. From targeted campaigns to social media mastery, we elevate your brand in the digital landscape for impactful visibility.”"
    },
    {
        name:"Mobile Apps",
        route:"/services/virtual-expertise/mobile-apps",
        description:"“Empower your brand with our custom mobile apps. Crafted for seamless user experiences, our innovative solutions enhance brand engagement and elevate your preferred-solutionssence in the digital landscape.”",
    },
    {
        name:"UI/UX",
        route:"/services/virtual-expertise/ui-ux",
        description:"“Elevate user satisfaction with our UI/UX design services. Crafted for optimal user experiences, our designs are intuitive, visually pleasing, and tailored to enhance digital interactions seamlessly.”",
    },
    {
        name:"SEO & SEM",
        route:"/services/virtual-expertise/seo-and-sem",
        description:"“Maximize online visibility with our SEO & SEM services. Boost rankings, enhance organic traffic, and drive results. Elevate your digital preferred-solutionssence with strategic optimization and targeted marketing at its finest.”",
    },
    {
        name:"Social Media Marketing",
        route:"/services/virtual-expertise/social-media-marketing",
        description:"“Elevate your brand’s online preferred-solutionssence with our Social Media Marketing expertise. We craft compelling strategies to engage your audience, boost visibility, and drive meaningful interactions for business success.”",
    },
    {
        name:"Audio & Video Productions",
        route:"/services/virtual-expertise/audio-and-video-productions",
        description:"“Elevate your brand with captivating Audio & Video Productions. Our experts craft compelling narratives and visually stunning content, ensuring a memorable and engaging experience for your audience.”",
    },
    {
        name:"Graphic designing",
        route:"/services/virtual-expertise/graphic-designing",
        description:"“Elevate your brand with captivating Graphic Design Productions. Our creative experts craft visually stunning designs that leave a lasting impreferred-solutionsssion. Transform ideas into compelling visuals for a standout identity.”",
    },
    {
        name:"Trademark Registration",
        route:"/services/preferred-solutions/trademark-registration",
        description:"Building a brand is an essential step, but protecting it with trademark registration is also key. Trademark registration online provides legal backing for your brand so that you can be sure to keep out all those who run the risk of using your name and work.",
    },
    {
        name:"Private Limited Company",
        route:"/services/preferred-solutions/private-limited-company",
        description:"Business registration has never been easier. Simplified company formation procedure makes it possible for you to register your company within hours and make your entrepreferred-solutionsneurial dream true.",
    },
    {
        name:"Proprietorship firm",
        route:"/services/preferred-solutions/proprietorship-firm",
        description:"Sole Proprietorship registration is the simplest way to start and run a business in India. It involves minimum paperwork and minimal cost, which makes it the most preferred-solutionsferred option among startups.",
    },
    {
        name:"Limited Liability Partnership",
        route:"/services/preferred-solutions/limited-liability-partnership",
        description:"LLP is a new form of doing business that combines the legal and tax advantages of a limited liability company (LLC) with the management flexibility, reduced costs and quasi-corporate structure of a partnership.",
    },
    {
        name:"GST Return Filing",
        route:"/services/preferred-solutions/gst-return-filing",
        description:"Taxation is a major responsibility of all entrepreneurs and professionals alike. Registration. Be it returns or payments, we help you with the hassle free and timely filing of GST.",
    },
    {
        name:"Accounting & Bookkeeping",
        route:"/services/preferred-solutions/accounting-and-bookkeeping",
        description:"Accounting is not just about recording the transactions, but also about managing your funds effectively. With timely accounting of all financial transactions, you can keep your focus on core business and ensure error-free accounting.",
    },
]