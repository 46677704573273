import '../styles/Portfolio.css';
import arr from '../assets/arrow_infobox.png';
import Navbar from '../innerComponents/Navbar';
import ReactPlayer from 'react-player/youtube'

function Portfolio({portfolios}){
    return(
        <>
            <div className="portfolio">
                <Navbar/>
                <div className="head">
                    <span>Portfolio</span>
                </div>
                <span className='type'>Images</span>
                <div className="content"> 
                {portfolios.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="img"><img src={item} alt="portfolio"/></div>
                        <div className='arrow'><img src={arr} alt='service'/></div>
                    </div>
                    ))}
                </div>
                <span className='type'>Videos</span>
                <div className="content"> 
                    <ReactPlayer width="300px" height="220px" url='https://youtube.com/shorts/9tgsgSzdv4E?feature=share' />
                    <ReactPlayer width="300px" height="220px" url='https://youtube.com/shorts/32-Onnsz90k?feature=share' />
                    <ReactPlayer width="300px" height="220px" url='https://youtube.com/shorts/g-zq4qCNSpU?feature=share' />
                </div>
            </div>
        </>
    )
}

export default Portfolio;