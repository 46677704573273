import '../styles/PricePlan.css';
import Navbar from '../innerComponents/Navbar';
import { Link } from 'react-router-dom';

function PricePlan(){
    return(
        <>
            <div style={{backgroundColor:"#000333"}}><Navbar/></div>
            <div className='price-plan'>
                <div className="grid">
                    <div className="card">
                        <span className="planname">CORAL</span>
                        <span className="planamount">₹7670</span>
                        <span className="subhead">Online Service</span>
                        <span className="duration">Valid for 3 months</span>
                        <Link to="/price-submit" key='1' className='button'>
                            <div className="button1"></div><div className="text">Enroll Now →</div>
                        </Link>
                        <div className="fullhorizontalline"></div>
                        <span className="facility">Credit Health Check Up</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Identification Of Errors</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Issue Resolutions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">10 Counseling Sessions</span>                        
                    </div>
                    <div className="card">
                        <div className="card">
                        <span className="planname">STANDARD</span>
                        <span className="planamount">₹14340</span>
                        <span className="subhead">Online Service</span>
                        <span className="duration">Valid for 5 months</span>
                        <Link to="/price-submit" key='1' className='button'>
                            <div className="button1"></div><div className="text">Enroll Now →</div>
                        </Link>
                        <div className="fullhorizontalline"></div>
                        <span className="facility">Credit Health Check Up</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Identification Of Errors</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Issue Resolutions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Unlimited Counseling Sessions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Score Improvement Module</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Free Credit reports</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">CH Summary</span>
                    </div>
                    </div>
                    <div className="card">
                        <div className="card">
                        <span className="planname">TITANIUM</span>
                        <span className="planamount">₹20060</span>
                        <span className="subhead">CH Service</span>
                        <span className="duration">Valid for 7 months</span>
                        <Link to="/price-submit" key='1' className='button'>
                            <div className="button1"></div><div className="text">Enroll Now →</div>
                        </Link>
                        <div className="fullhorizontalline"></div>
                        <span className="facility">Credit Health Check Up</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Analysis of Credit Report</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Identification Of Errors</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Issue Resolutions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Repayment options</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Score Improvement Module</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Unlimited Counseling Sessions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Personalized Credit expert</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Free Credit reports</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">CH Summary</span>
                    </div>
                    </div>
                    <div className="card">
                        <div className="card">
                        <span className="planname">PREMIUM</span>
                        <span className="planamount">₹29500</span>
                        <span className="subhead">CH Service</span>
                        <span className="duration">Valid for 9 months</span>
                        <Link to="/price-submit" key='1' className='button'>
                            <div className="button1"></div><div className="text">Enroll Now →</div>
                        </Link>
                        <div className="fullhorizontalline"></div>
                        <span className="facility">Credit Health Check Up</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Analysis of Credit Report</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Identification Of Errors</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Issue Resolutions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Repayment options</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Score Improvement Module</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Unlimited Counseling Sessions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Personalized Credit expert</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Multi Bureau Analysis</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Loan Assisatance</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Debt Consolidation</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">CH Summary</span>
                    </div>
                    </div>
                    <div className="card">
                        <div className="card">
                        <span className="planname">CUSTOMISED</span>
                        <span className="planamount">₹59000</span>
                        <span className="subhead">CH Service</span>
                        <span className="duration">Valid for one year</span>
                        <Link to="/price-submit" key='1' className='button'>
                            <div className="button1"></div><div className="text">Enroll Now →</div>
                        </Link>
                        <div className="fullhorizontalline"></div>
                        <span className="facility">Credit Health Check Up</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Analysis of Credit Report</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Identification Of Errors</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Issue Resolutions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Repayment options</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Score Improvement Module</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Unlimited Counseling Sessions</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Free Credit reports</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Personalized Senior Credit expert</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Multi Bureau Report Analysis</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Loan Assisatance</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">Debt Consolidation</span>
                        <div className="halfhorizontalline"></div>
                        <span className="facility">CH Summary</span>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricePlan;