import { Outlet } from "react-router-dom";
import Navbar from "../innerComponents/Navbar";
import '../styles/Services.css';

function Services(){
    return(
        <>   
            <div className="service-main">
                <Navbar/>       
                <Outlet/>
            </div>
        </>
    )
}
export default Services;