import React, { useEffect, useRef } from 'react';
import '../styles/ServiceSlider.css';

const ServiceSlider = () => {
  const logosRef = useRef(null);

  useEffect(() => {
    const copy = logosRef.current.cloneNode(true);
    logosRef.current.parentNode.appendChild(copy);
  }, []);

  return (
    <div className="logos">
      <div ref={logosRef} className="logos-slide sl">
        <div className="logo-text sl">Website Development</div>
        <div className="logo-text sl">*</div>
        <div className="logo-text sl">Mobile Apps Development</div>
        <div className="logo-text sl">*</div>
        <div className="logo-text sl">SEO & SEM</div>
        <div className="logo-text sl">*</div>
        <div className="logo-text sl">UI/UX</div>
        <div className="logo-text sl">*</div>
        <div className="logo-text sl">Graphic Designing</div>
        <div className="logo-text sl">*</div>
        <div className="logo-text sl">Social Media Marketing</div>
        <div className="logo-text sl">*</div>
        <div className="logo-text sl">Branding</div>
        <div className="logo-text sl">*</div>
      </div>
    </div>
  );
};

export default ServiceSlider;
