import one from '../assets/1.jpg';
import two from '../assets/2.jpg';
import three from '../assets/3.jpg';
import four from '../assets/4.jpg';
import five from '../assets/5.jpg';
import six from '../assets/6.jpg';
import seven from '../assets/7.jpg';
import eight from '../assets/8.jpg';
import nine from '../assets/9.jpg';
import ten from '../assets/10.jpg';
import eleven from '../assets/11.jpg';
import twelve from '../assets/12.jpg';
import thirteen from '../assets/13.jpg';
import forteen from '../assets/14.jpg';
import fifteen from '../assets/15.jpg';
import sixteen from '../assets/16.jpg';

export const portfolios = [one,two,three,four,five,six,seven,eight,nine,ten,eleven,twelve,thirteen,forteen,fifteen,sixteen];