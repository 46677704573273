import Navbar from "../innerComponents/Navbar";
import "../styles/Error.css";

const Error = () => {
  return (
    <>
      <div style={{backgroundColor:'#000333'}}><Navbar/></div>
      <div className="errormain">
        ERROR<br/>404
      </div>
    </>

  )
};

export default Error;
