import HomeSection2 from '../innerComponents/HomeSection2';
import '../styles/About.css';
import { Link } from 'react-router-dom';
import ProjectCollaboration from '../innerComponents/ProjectCollaboration';
import Navbar from '../innerComponents/Navbar';

function About(){
    return(
        <>
            <div className='about'>
                <Navbar/>
                <div className="aboutspan" style={{width:'100%',textAlign:'center',padding:'0 0'}}>
                    <span style={{color:'#FFFFEF',fontSize:'2em',fontWeight:'bold'}}>About Us</span>
                </div> 
                <div className="title" style={{marginTop:'50px'}}>
                    <div className='quote'>
                        <span className='abt'>About Company</span>
                        <span className='hd'>Unique Digital Ideas for Successful Business</span>
                        <span className='txt'>Welcome to Qiyam Business Solutions LLP, where our commitment to Quality, Goodness, and Trust forms the bedrock of everything we do. More than mere words, these values are the guiding principles that propel us forward in our mission to be your trusted partner on the path to excellence and success.</span>
                        <span className="txt">In today's dynamic business environment, success isn't just about achieving targets; it's about the journey of continuous improvement and growth. At Qiyam, we understand the intricacies of this journey, and we are dedicated to walking alongside you every step of the way.</span>
                        <span className="txt">Our extensive range of services isn't just a checklist; it's a carefully curated selection tailored to meet the evolving needs of modern businesses. Whether it's enhancing your credit health, creating compelling branding strategies, establishing a robust online presence, or ensuring compliance with legal and financial standards, we're here to ensure that every facet of your enterprise reaches its full potential.</span>
                        <span className="txt">But we're more than just service providers – we're your allies, advocates, and confidants. Whether you're a fledgling startup or an established enterprise, we're here to offer unwavering support, expert guidance, and personalized solutions to help you navigate the challenges and seize the opportunities that come your way.</span>
                        <span className="txt">So, join us on this journey towards excellence and success. With Qiyam Business Solutions LLP at your side, the possibilities are boundless, and the path to success is illuminated by our unwavering commitment to Quality, Goodness, and Trust – not just as a tagline, but as a promise.</span>
                        <Link to="/contact" key='1' className='button'>
                            <div className="button1"></div><div className="text">Get In Touch →</div>
                        </Link>
                    </div>
                </div>
                <div><HomeSection2/></div>
                <div className="content">
                    <div className="who">
                        <div className="headwho"><span>Who We Are?</span></div>
                        <div className="whocontent">“Exemplifying business excellence, we specialize in innovative Digital Solutions and precise Legal Compliance Support. Our Advanced Digital Solutions redefine online presence, seamlessly blending legal expertise with innovation. Experience unparalleled success as we navigate intricacies, propelling businesses forward through a unique fusion of cutting-edge technology and meticulous compliance.”</div>
                    </div>
                    <div className="what">
                        <div className="headwhat"><span>What We Do?</span></div>
                        <div className="whatcontent">“Embark on a transformative journey with our diverse services. Navigate legal intricacies with Legal Compliance Support. Innovate your digital presence using Advanced Digital Solutions. Explore services like Web & App Development, Branding, Digital Marketing, UI/UX Design, SEO, Video Productions, and more. Elevate your business seamlessly with precision, innovation, and expert guidance, all under one roof.”</div>
                    </div>
                </div> 
                <ProjectCollaboration/>
            </div>
        </>
    )
} 
export default About;