import '../styles/Contact.css'
import { FaWhatsapp,FaInstagram,FaFacebook,FaLinkedinIn,FaLocationArrow,FaPhone, FaYoutube } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Navbar from '../innerComponents/Navbar';
import React from 'react';
import { Link } from 'react-router-dom';
function Contact(){  
    const onSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
  
      formData.append("access_key", "617f87ff-6101-473c-bcac-1d8c02d184a6");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        event.target.reset();
      } else {
        console.log("Error", data);
      }
    };
    return(
        <>
            <div className='contactbody'>
                <Navbar/>
                <div className="title">
                    <span>Contact Us</span>
                </div>
                <div className='contactcontainer'>
                    <div className="contactcontainer">
                        <div className="row">
                            <section className="col left">
                                <div className="contactTitle">
                                    <h2>Let’s Start Working Together. Get in Touch with Us!</h2>
                                    <p>Get in touch to discuss your brand requirements today. Please give us a call, drop us an email.</p>
                                </div>
                                <div className="contactInfo">                                    
                                    <div className="iconGroup">
                                        <div className="icon">
                                            <div className='i'><FaPhone/></div>
                                        </div>
                                        <div className="details">
                                            <span>Phone</span>
                                            <span>+91 9446122733</span>
                                        </div>
                                    </div>
                                    <div className="iconGroup">
                                        <div className="icon">
                                            <div className='i'><MdEmail/></div>
                                        </div>
                                        <div className="details">
                                            <span>Email</span>
                                            <span>mail@qbsllp.in</span>
                                        </div>
                                    </div>
                                    <div className="iconGroup">
                                        <div className="icon">
                                            <div className='i'><FaLocationArrow/></div>
                                        </div>
                                        <div className="details">
                                            <span>Location</span>
                                            <span>Qiyam Business Solutions LLP,</span>
                                            <span>Regd Office adress. 1/126,</span>
                                            <span>Kozhikode, 673323</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="socialMedia">
                                    <div className='a'><Link to='https://www.facebook.com/share/bBigLmAdpR4YMtdi/?mibextid=qi2Omg' className='i'><FaFacebook/></Link></div>
                                    <div className='a'><Link to='https://www.instagram.com/qbsllp?igsh=eXVmc2E2bnE5M3R3' className='i'><FaInstagram/></Link></div>
                                    <div className='a'><Link to='https://wa.me/919446122733' className='i'><FaWhatsapp/></Link></div>
                                    {/* <div className='a'><Link to='' className='i'><FaTwitter/></Link></div> */}
                                    <div className='a'><Link to='https://in.linkedin.com/in/qiyam-business-solutions-225170301' className='i'><FaLinkedinIn/></Link></div>
                                    <div className='a'><Link to='https://youtube.com/@qbsllp?si=oeVyyNPHGy5znjht' className='i'><FaYoutube/></Link></div>
                                </div>
                            </section>
                            <section className="col right">
                                <form onSubmit={onSubmit} className="messageForm">

                                    <div className="inputGroup fullWidth">
                                        <span className='drop'>Drop Us a Line</span>
                                    </div>
                                    <div className="inputGroup halfWidth" style={{display:'none'}}>
                                        <input type="text" name="Message" value="Message"/>
                                        <label>Message</label>
                                    </div>
                                    <div className="inputGroup fullWidth">
                                        <input type="text" name="Name" required="required"/>
                                        <label>Your Name</label>
                                    </div>
                                    <div className="inputGroup fullWidth" style={{display:'none'}}>
                                        <input type="text" name=""/>
                                        <label>None</label>
                                    </div>
                                    <div className="inputGroup fullWidth">
                                        <input type="email" name="Email" required="required"/>
                                        <label>Email</label>
                                    </div>
                                    
                                    <div className="inputGroup fullWidth">
                                        <input type="text" name="Phone" required="required"/>
                                        <label>Phone</label>
                                    </div>
                                    <div className="inputGroup fullWidth">
                                        <textarea required="required" name='Message'></textarea>
                                        <label>Your Message</label>
                                    </div>
                                    <button type='submit' className='button'>
                                        <div className="button1"></div><div className="text">Get In Touch →</div>
                                    </button>
                                </form>
                            </section>
                        </div>
                        <div className="address">
                            <div className="head">
                                <span>Address for Communication</span>
                            </div>
                            <div className="content">
                                <span>Qiyam Business Solutions LLP</span>
                                <span>Regd Office adress. 1/126,</span>
                                <span>Kozhikode, 673323</span>
                            </div>
                            <div className="head">
                                <span>Contact</span>
                            </div>
                            <div className="content contact">
                                <span>Email:<span style={{color:'#007bff'}}> mail@qbsllp.in</span></span>
                                <span>Phone:<span style={{color:'#007bff'}}> +91 9446122733</span></span>
                                <span>Website:<Link to='https://qbsllp.in/' style={{color:'#007bff',textDecoration:'none'}}> www.qbsllp.in</Link></span>
                            </div>
                        </div>
                        {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15057.24092920135!2d75.73797298451169!3d11.388460215409397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6605f2b3bf091%3A0x11ec5d9c70b18079!2sAtholi%2C%20Kerala!5e0!3m2!1sen!2sin!4v1711333353927!5m2!1sen!2sin"
                        width="100%"
                        height="500"
                        title='Qiyam'
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        ></iframe> */}
                        {/* <LoadScript googleMapsApiKey={null}>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '500px',
                                    width: '100%',
                                    margin: '50px 0'
                                    }}
                                zoom={10}
                                center={{lat: 37.7749,
                                    lng: -122.4194}}
                            ></GoogleMap>
                        </LoadScript> */}
                    </div>                   
                </div>
            </div>   
        </>
    )
}
export default Contact;