import accounting from '../assets/servicelogo/accounting.png';
import audiovideo from '../assets/servicelogo/audiovideo.png';
import branding from '../assets/servicelogo/branding.png';
import digital from '../assets/servicelogo/digital.png';
import graphic from '../assets/servicelogo/graphic.png';
import gst from '../assets/servicelogo/gst.png';
import limited from '../assets/servicelogo/limited liab partnership.png';
import mobileapp from '../assets/servicelogo/mobileapp.png';
import proprator from '../assets/servicelogo/proprator.png';
import pvtltd from '../assets/servicelogo/pvtltd.png';
import seo from '../assets/servicelogo/seo.png';
import trademark from '../assets/servicelogo/trademark.png';
import uiux from '../assets/servicelogo/uiux.png';
import webapp from '../assets/servicelogo/webapp.png';
import credit from '../assets/credit.png';
export const services = [
    {
        image:credit,
        name:"Credit Health Improvement",
        sroute:"credit-health-improvement",
        route:"/services/credit-health-improvement/credit-health-improvement",
        description:"“As experts in Credit Health Improvement, our mission is to guide individuals toward financial well-being. Led by certified Credit Experts, we analyze credit reports, identify issues, and develop personalized plans for improvement—going beyond scores to enhance overall credit health.”",
        category:"credit-health-improvement",
        about_head:"Unlock Your Financial Potential with Credit Health Improvement",
        about:"In the complex world of credit, navigating toward financial stability can often feel like an uphill battle. That's where we come in. As leaders in Credit Health Improvement, our mission is clear: to guide individuals toward a brighter financial future.\nLed by certified Credit Experts, we offer a suite of in-demand services designed to elevate your credit health and empower you to take control of your financial destiny. Our process begins with a comprehensive analysis of your credit reports, where we meticulously identify any issues or areas for improvement.\nBut we don't stop there. Our approach goes beyond mere credit scores; we focus on enhancing your overall credit health. By understanding your unique financial situation and goals, we develop personalized plans tailored to your needs, ensuring that every step you take is a step toward financial well-being.\nWhether you're looking to improve your credit score, qualify for better loan rates, or simply gain a deeper understanding of your financial health, we're here to help. Trust our team of experts to be your partner on this journey toward a brighter financial future. Together, let's unlock your full potential and pave the way for a lifetime of financial success.",
    },
    {
        image:webapp,
        name:"Web & App Development",
        sroute:"web-and-app-development",
        route:"/services/virtual-expertise/web-and-app-development",
        description:"“Elevate your online preferred-solutionssence with our expert Web & App Development services. We craft tailored digital solutions, ensuring your business stands out in the ever-evolving digital landscape.”",
        category:"virtual-expertise",
        about_head:"Craft Your Digital Universe: Empower Your Online Presence with Expert Web Development",
        about:"Welcome to the gateway of unparalleled digital transformation! At our digital atelier, we don't just create websites and apps; we forge cutting-edge experiences that redefine online excellence. Prepare to embark on a journey of innovation and distinction as we elevate your online presence to celestial heights!\nIn the vast realm of the digital universe, standing out is not just a goal but a necessity. That's where our expert team of visionaries steps in. With meticulous craftsmanship and boundless creativity, we sculpt bespoke digital solutions tailored to your unique needs. From sleek and responsive websites that captivate audiences at first glance to intuitive and immersive apps that engage users on every level, we ensure that your brand shines brightly amidst the ever-evolving digital landscape.\nBut we don't stop at mere creation; we pave the way for digital success. Armed with the latest technologies and strategic insights, we navigate the complexities of the digital realm with finesse. Our mission is not just to meet expectations but to exceed them, delivering results that propel your business forward with unprecedented momentum.\nSo, are you ready to transcend the ordinary and embrace the extraordinary? Join us on this exhilarating journey as we revolutionize the way the world sees your brand. Together, let's rewrite the rules of online engagement and chart a course towards digital greatness. Your destiny awaits—let's make it extraordinary, together.",
    },
    {
        image:branding,
        name:"Branding",
        route:"/services/virtual-expertise/branding",
        sroute:"branding",
        description:"“Elevate your brand with our expert branding services. We craft compelling visual narratives, ensuring a distinct and memorable identity that resonates with your audience. Transform your brand essence today.”",
        category:"virtual-expertise",
        about_head:"Transform Your Brand Identity into an Icon of Distinction",
        about:"Step into the realm of brand metamorphosis with our transformative branding services! At our creative sanctuary, we don't just shape brands; we orchestrate captivating visual symphonies that etch unforgettable impressions in the minds of your audience. Prepare to embark on a voyage of brand elevation, where every stroke of creativity is a brushstroke of distinction!\nIn the cacophony of modern marketing, standing out is not a choice—it's a mandate. That's where our seasoned artisans come into play. With a blend of artistry and strategy, we craft bespoke visual narratives that breathe life into your brand essence. From sleek logos that speak volumes to cohesive brand identities that resonate across every touchpoint, we ensure that your brand leaves an indelible mark in the hearts and minds of your audience.\nBut we don't just stop at aesthetics; we delve deep into the soul of your brand, unraveling its essence and amplifying its voice. With meticulous attention to detail and an unwavering commitment to excellence, we transform your brand from a mere entity into an icon of distinction. It's not just about making an impression; it's about making a statement—one that reverberates across industries and leaves a lasting legacy.\nSo, are you ready to redefine the narrative of your brand? Join us on this transformative journey as we unleash the full potential of your brand identity. Together, let's transcend boundaries, break molds, and sculpt a brand that resonates with the hearts and minds of your audience. The spotlight awaits—let's illuminate the world with your brand brilliance.",
    },
    {
        image:digital,
        name:"Digital Marketing",
        route:"/services/virtual-expertise/digital-marketing",
        sroute:"digital-marketing",
        description:"“Amplify your brand’s online preferred-solutionssence with our strategic Digital Marketing solutions. From targeted campaigns to social media mastery, we elevate your brand in the digital landscape for impactful visibility.”",
        category:"virtual-expertise",
        about_head:"Propel Your Brand to New Heights in the Digital Landscape",
        about:"Prepare to revolutionize your brand's online dominance with our strategic Digital Marketing solutions! Enter the realm of digital dynamism where every click, every engagement, propels your brand to the forefront of the digital arena. Brace yourself for a journey of unprecedented visibility and impact!\nIn the vast expanse of the digital universe, mere presence is not enough—it's about making waves. That's where our team of digital maestros comes into play. With a blend of data-driven insights and creative flair, we orchestrate targeted campaigns that resonate with your audience on a profound level. From captivating social media strategies that spark conversations to precision-targeted ads that cut through the noise, we ensure that your brand not only stands out but leaves an indelible mark in the minds of your audience.\nBut we don't just stop at visibility; we harness the power of digital alchemy to drive tangible results. With a keen eye for ROI and a relentless pursuit of excellence, we transform your digital presence into a revenue-generating powerhouse. It's not just about likes and shares; it's about conversions, sales, and sustainable growth.\nSo, are you ready to amplify your brand's digital resonance? Join us on this exhilarating journey as we navigate the ever-changing currents of the digital landscape. Together, let's craft a narrative of success, where your brand shines brightly amidst the digital noise, commanding attention and driving impact like never before. The digital stage awaits—let's make your brand the star of the show!",
    },
    {
        image:mobileapp,
        name:"Mobile Apps",
        route:"/services/virtual-expertise/mobile-apps",
        sroute:"mobile-apps",
        description:"“Empower your brand with our custom mobile apps. Crafted for seamless user experiences, our innovative solutions enhance brand engagement and elevate your preferred-solutionssence in the digital landscape.”",
        category:"virtual-expertise",
        about_head:"Empower Your Brand with Cutting-Edge Mobile Apps",
        about:"Step into the future of brand empowerment with our custom mobile apps that redefine the boundaries of user experience. At the intersection of creativity and functionality, we engineer digital solutions tailored to your brand's unique needs, propelling your business towards unparalleled success.\nIn today's hyper-connected world, mobile apps are the cornerstone of digital engagement. That's why our team of visionary developers meticulously crafts each app to deliver seamless experiences that captivate and delight users. From intuitive navigation to stunning design elements, every detail is meticulously curated to ensure maximum impact and user satisfaction.\nBut we don't just stop at aesthetics; we understand the importance of functionality in driving meaningful interactions. Our apps are engineered with cutting-edge technologies and robust frameworks to provide unparalleled performance and reliability. Whether it's enhancing customer engagement, streamlining internal processes, or driving sales, our mobile apps empower your brand to achieve its goals with precision and efficiency.\nSo, are you ready to elevate your brand's presence in the digital realm? Join us on this transformative journey as we harness the power of mobile technology to unlock new opportunities and propel your brand towards digital greatness. Together, let's craft experiences that inspire, engage, and leave a lasting impression in the hearts and minds of your audience.",
    },
    {
        image:uiux,
        name:"UI/UX",
        route:"/services/virtual-expertise/ui-ux",
        sroute:"ui-ux",
        description:"“Elevate user satisfaction with our UI/UX design services. Crafted for optimal user experiences, our designs are intuitive, visually pleasing, and tailored to enhance digital interactions seamlessly.”",
        category:"virtual-expertise",
        about_head:"Unlock the Power of Seamless Digital Interactions with Our UI/UX Design Services",
        about:"Discover a new standard of user satisfaction with our expertly crafted UI/UX designs. Engineered for optimal user experiences, our designs seamlessly blend intuitive navigation, visually captivating aesthetics, and personalized interactions to elevate your digital presence to unprecedented heights.\nIn today's fast-paced digital landscape, user experience is paramount. That's why our team of skilled designers approaches each project with a meticulous focus on usability and aesthetics. From the initial wireframes to the final polished designs, every aspect is carefully curated to ensure that users not only enjoy interacting with your digital platforms but also feel empowered and inspired by the experience.\nBut our commitment to excellence doesn't end with aesthetics; we understand that great design goes hand in hand with functionality. Our UI/UX designs are meticulously crafted to enhance digital interactions, streamline processes, and drive meaningful engagement. Whether it's a website, mobile app, or digital platform, our designs are tailored to meet the unique needs of your audience while aligning seamlessly with your brand identity.\nSo, are you ready to elevate your digital presence with exceptional UI/UX design? Join us on this transformative journey as we redefine the standards of user satisfaction and craft experiences that leave a lasting impression. Together, let's create digital masterpieces that inspire, engage, and delight users across every touchpoint.",
    },
    {
        image:seo,
        name:"SEO & SEM",
        route:"/services/virtual-expertise/seo-and-sem",
        sroute:"seo-and-sem",
        description:"“Maximize online visibility with our SEO & SEM services. Boost rankings, enhance organic traffic, and drive results. Elevate your digital preferred-solutionssence with strategic optimization and targeted marketing at its finest.”",
        category:"virtual-expertise",
        about_head:"Maximize Online Visibility and Drive Results with Our SEO & SEM Services",
        about:"Unleash the Full Potential of Your Digital Presence with Strategic Optimization and Targeted Marketing.\nExperience a paradigm shift in online visibility with our comprehensive SEO & SEM services designed to propel your brand to the forefront of search engine results. From boosting rankings to enhancing organic traffic, we employ cutting-edge strategies to ensure maximum exposure and tangible results.\nIn the competitive landscape of the digital realm, visibility is key. That's why our team of SEO & SEM experts utilizes a combination of proven techniques and innovative approaches to elevate your digital presence. Whether it's optimizing your website for search engines, crafting compelling ad campaigns, or analyzing data to fine-tune strategies, we leave no stone unturned in our quest to drive meaningful results for your brand.\nBut our commitment to excellence doesn't stop there; we understand that success is measured not just in clicks but in conversions. That's why our strategies are tailored to deliver not only increased traffic but also tangible ROI. From increasing brand awareness to driving sales and conversions, our SEO & SEM services are designed to align seamlessly with your business goals and objectives.\nSo, are you ready to maximize your online visibility and drive results like never before? Join us on this transformative journey as we leverage the power of strategic optimization and targeted marketing to elevate your digital presence to new heights. Together, let's unlock the full potential of your brand and pave the way for sustained success in the digital landscape.",
    },
    {
        image:digital,
        name:"Social Media Marketing",
        route:"/services/virtual-expertise/social-media-marketing",
        sroute:"social-media-marketing",
        description:"“Elevate your brand’s online preferred-solutionssence with our Social Media Marketing expertise. We craft compelling strategies to engage your audience, boost visibility, and drive meaningful interactions for business success.”",
        category:"virtual-expertise",
        about_head:"Harness the Power of Social Media to Propel Your Brand Towards Unprecedented Success",
        about:"Embark on a journey of digital transformation with our unparalleled Social Media Marketing expertise. Crafted to perfection, our strategies are designed to captivate your audience, amplify your brand's visibility, and drive meaningful interactions that translate into tangible business success.\nIn today's digital landscape, social media is the beating heart of brand engagement. That's why our team of seasoned marketers combines creativity with data-driven insights to craft compelling strategies tailored to your brand's unique identity and objectives. From engaging content that sparks conversations to targeted advertising campaigns that reach the right audience at the right time, we ensure that your brand stands out amidst the social media noise.\nBut our commitment to excellence goes beyond mere visibility; we understand that meaningful interactions are the cornerstone of lasting relationships. That's why our Social Media Marketing strategies are designed to foster authentic connections with your audience, driving brand loyalty and advocacy. Whether it's sparking discussions, responding to queries, or leveraging user-generated content, we empower your brand to become a beacon of trust and credibility in the digital sphere.\nSo, are you ready to elevate your brand's online presence and unlock new opportunities for growth? Join us on this transformative journey as we harness the power of Social Media Marketing to propel your brand towards unparalleled success. Together, let's craft a narrative of engagement, visibility, and meaningful interactions that leave a lasting impression in the hearts and minds of your audience.",
    },
    {
        image:audiovideo,
        name:"Audio & Video Productions",
        route:"/services/virtual-expertise/audio-and-video-productions",
        sroute:"audio-and-video-productions",
        description:"“Elevate your brand with captivating Audio & Video Productions. Our experts craft compelling narratives and visually stunning content, ensuring a memorable and engaging experience for your audience.”",
        category:"virtual-expertise",
        about_head:"Crafting Immersive Experiences Through Compelling Narratives and Visually Stunning Content",
        about:"Prepare to transcend the ordinary and captivate your audience with our expert Audio & Video Productions. From enchanting storytelling to visually stunning content, our seasoned professionals are dedicated to creating memorable experiences that resonate with your audience long after the screen fades to black.\nIn the fast-paced world of digital content consumption, standing out is not just a goal—it's a necessity. That's why our team of creative visionaries approaches each project with boundless passion and ingenuity. Whether it's a cinematic masterpiece that tugs at the heartstrings or a high-energy promotional video that ignites excitement, we tailor our productions to reflect the unique essence of your brand while captivating audiences across every platform.\nBut our commitment to excellence extends beyond mere aesthetics; we understand the power of storytelling in forging meaningful connections. That's why our Audio & Video Productions are not just about visuals and sounds—they're about emotions, experiences, and moments that leave a lasting impression. Whether it's evoking laughter, inspiring action, or sparking curiosity, we craft narratives that resonate deeply with your audience, driving engagement and brand affinity.\nSo, are you ready to elevate your brand with captivating Audio & Video Productions that leave a lasting impact? Join us on this cinematic journey as we harness the power of storytelling and visual artistry to transform your brand into an unforgettable experience. Together, let's craft narratives that inspire, entertain, and connect with audiences on a profound level, setting your brand apart in the digital landscape.",
    },
    {
        image:graphic,
        name:"Graphic designing",
        route:"/services/virtual-expertise/graphic-designing",
        sroute:"graphic-designing",
        description:"“Elevate your brand with captivating Graphic Design Productions. Our creative experts craft visually stunning designs that leave a lasting impreferred-solutionsssion. Transform ideas into compelling visuals for a standout identity.”",
        category:"virtual-expertise",
        about_head:"Crafting Visual Masterpieces to Define Your Brand Identity",
        about:"Prepare to make a bold statement and elevate your brand with our captivating Graphic Design Productions. Led by our team of creative experts, we specialize in crafting visually stunning designs that not only catch the eye but also leave a lasting impression, ensuring your brand stands out in the crowded digital landscape.\nIn a world where first impressions matter more than ever, our Graphic Design Productions are designed to be unforgettable. Whether it's a sleek logo that embodies your brand's essence, a striking marketing collateral that demands attention, or a captivating social media graphic that stops users mid-scroll, our designs are meticulously crafted to reflect the unique personality and values of your brand.\nBut our dedication to excellence goes beyond aesthetics; we understand that great design is about more than just looking good—it's about communicating effectively. That's why our Graphic Design Productions are rooted in strategic thinking and audience understanding. We work closely with you to transform ideas into compelling visuals that resonate with your target audience, driving engagement, loyalty, and ultimately, business success.\nSo, are you ready to transform your brand with captivating Graphic Design Productions that leave a lasting impact? Join us on this creative journey as we bring your vision to life and redefine what it means to stand out in the digital world. Together, let's craft visual masterpieces that not only capture attention but also inspire action, setting your brand apart as a true leader in your industry.",
    },
    {
        image:trademark,
        name:"Trademark Registration",
        sroute:"trademark-registration",
        route:"/services/preferred-solutions/trademark-registration",
        description:"Building a brand is an essential step, but protecting it with trademark registration is also key. Trademark registration online provides legal backing for your brand so that you can be sure to keep out all those who run the risk of using your name and work.",
        category:"preferred-solutions",
        about_head:"Protect Your Brand with Legal Assurance",
        about:"Building a brand is just the beginning of your journey towards success, but safeguarding it is equally crucial. With trademark registration online, you not only establish legal ownership of your brand but also fortify it against potential infringements.\nTrademark registration offers you the peace of mind knowing that your brand is legally protected from unauthorized use by others. It serves as a powerful deterrent against those who might attempt to capitalize on your hard-earned reputation and goodwill.\nBy registering your trademark, you gain exclusive rights to use your brand name and logo in connection with your products or services. This not only prevents competitors from confusing consumers but also enables you to take legal action against any unauthorized use, ensuring the integrity and value of your brand remain intact.\nDon't leave your brand's future to chance. Invest in trademark registration today and safeguard your brand's legacy for years to come.",
    },
    {
        image:pvtltd,
        name:"Private Limited Company",
        sroute:"private-limited-company",
        route:"/services/preferred-solutions/private-limited-company",
        description:"Business registration has never been easier. Simplified company formation procedure makes it possible for you to register your company within hours and make your entrepreferred-solutionsneurial dream true.",
        category:"preferred-solutions",
        about_head:"Seize the Opportunity to Bring Your Business Idea to Life",
        about:"Embarking on your entrepreneurial journey has never been more accessible. With a simplified company formation procedure, registering your Private Limited Company is now a streamlined process, allowing you to turn your business dreams into reality in a matter of hours.\nGone are the days of navigating complex legal formalities and bureaucratic red tape. Our efficient registration process puts you in the driver's seat, empowering you to establish your company with ease and efficiency.\nWhether you're a seasoned entrepreneur or a budding visionary, our team of experts is here to guide you every step of the way. From selecting the right business structure to filing necessary paperwork, we ensure a seamless registration process that aligns with your goals and aspirations.\nDon't let administrative hurdles stand in the way of your entrepreneurial ambitions. Embrace the simplicity of Private Limited Company registration and take the first step towards building a thriving business that stands the test of time.",
    },
    {
        image:proprator,
        name:"Proprietorship firm",
        sroute:"proprietorship-firm",
        route:"/services/preferred-solutions/proprietorship-firm",
        description:"Sole Proprietorship registration is the simplest way to start and run a business in India. It involves minimum paperwork and minimal cost, which makes it the most preferred-solutionsferred option among startups.",
        category:"preferred-solutions",
        about_head:"Start Your Business Journey Seamlessly with Sole Proprietorship Registration",
        about:"Embarking on your entrepreneurial journey has never been more straightforward. Sole Proprietorship registration offers you the simplest and most cost-effective way to start and run a business in India, with minimal paperwork and low costs making it the preferred choice for startups.\nWith Sole Proprietorship registration, you can bring your business idea to life with ease and efficiency. Say goodbye to the complexities of establishing a business entity and hello to the simplicity of being your own boss.\nWhether you're a first-time entrepreneur or a seasoned business owner looking to explore new ventures, Sole Proprietorship registration provides you with the flexibility and freedom to pursue your dreams without unnecessary hurdles.\nJoin the ranks of successful startups across India who have chosen Sole Proprietorship as their preferred business structure. Take the first step towards realizing your entrepreneurial aspirations today and watch your business thrive with ease.",
    },
    {
        image:limited,
        name:"Limited Liability Partnership",
        sroute:"limited-liability-partnership",
        route:"/services/preferred-solutions/limited-liability-partnership",
        description:"LLP is a new form of doing business that combines the legal and tax advantages of a limited liability company (LLC) with the management flexibility, reduced costs and quasi-corporate structure of a partnership.",
        category:"preferred-solutions",
        about_head:"The Ultimate Fusion of Legal Protection and Business Flexibility",
        about:"Limited Liability Partnership (LLP) represents the pinnacle of modern business structures, offering the best of both worlds: the legal and tax benefits of a limited liability company (LLC) combined with the management flexibility, reduced costs, and quasi-corporate structure of a partnership.\nWith LLP, entrepreneurs can enjoy the peace of mind that comes with limited liability protection, shielding personal assets from business liabilities. At the same time, they benefit from the simplified management structure and tax advantages traditionally associated with partnerships.\nLLP is not just a business entity; it's a strategic advantage that empowers entrepreneurs to navigate the complexities of modern commerce with confidence and agility. Whether you're a startup looking to minimize risk, a small business seeking operational flexibility, or a professional firm aiming to enhance credibility, LLP offers a versatile solution tailored to your unique needs.\nJoin the ranks of forward-thinking businesses that have embraced LLP as their preferred business structure. Experience the freedom to innovate, the security of legal protection, and the flexibility to chart your own path to success. With LLP, the possibilities are limitless.",
    },
    {
        image:gst,
        name:"GST Return Filing",
        sroute:"gst-return-filing",
        route:"/services/preferred-solutions/gst-return-filing",
        description:"Taxation is a major responsibility of all entrepreneurs and professionals alike. Registration. Be it returns or payments, we help you with the hassle free and timely filing of GST.",
        category:"preferred-solutions",
        about_head:"Streamline Your Taxation Process with Hassle-Free GST Return Filing",
        about:"Taxation is a crucial aspect of every business, and ensuring timely compliance is essential for maintaining financial health and regulatory adherence. With the complexities of Goods and Services Tax (GST) in India, navigating the filing process can be daunting.\nAt our firm, we understand the challenges entrepreneurs and professionals face when it comes to GST compliance. That's why we offer comprehensive assistance to streamline the GST return filing process, ensuring hassle-free and timely submissions.\nFrom registration to filing returns and making payments, our team of experts is dedicated to providing personalized support tailored to your specific needs. We leverage our expertise and experience to simplify the complexities of GST, guiding you through each step with precision and efficiency.\nWith our assistance, you can focus on what matters most—growing your business and achieving your goals—while we handle the intricacies of GST compliance on your behalf. Say goodbye to the stress of tax filing and hello to peace of mind with our reliable and efficient GST return filing services.",
    },
    {
        image:accounting,
        name:"Accounting & Bookkeeping",
        sroute:"accounting-and-bookkeeping",
        route:"/services/preferred-solutions/accounting-and-bookkeeping",
        description:"Accounting is not just about recording the transactions, but also about managing your funds effectively. With timely accounting of all financial transactions, you can keep your focus on core business and ensure error-free accounting.",
        category:"preferred-solutions",
        about_head:"Master Your Finances with Expert Accounting & Bookkeeping Services",
        about:"Accounting is the backbone of every successful business, providing invaluable insights into your financial health and helping you make informed decisions. However, it's not just about recording transactions—it's about managing your funds effectively and ensuring error-free accounting to keep your business on track.\nAt our firm, we offer more than just basic bookkeeping; we provide comprehensive accounting services designed to streamline your financial operations and optimize your business performance. Our team of skilled professionals takes care of all your accounting needs, from recording transactions to preparing financial statements and everything in between.\nWith our timely and accurate accounting services, you can rest assured that your financial records are in good hands, allowing you to focus on what you do best—running your business. Say goodbye to the stress of managing finances and hello to peace of mind with our reliable accounting solutions.\nWhether you're a small startup or a growing enterprise, our tailored accounting services are designed to meet your specific needs and help you achieve your financial goals. Trust us to be your partner in success, and together, we'll pave the way for a prosperous future for your business.",
    },
]