import '../styles/HomeSection1.css';
import { Link } from 'react-router-dom';
import logo1 from '../assets/logo1.png';
import logo2 from '../assets/logo2.png';

function HomeSection1(){
    return(
        <>
            <div className='home-section1'>
                <div className='quote'>
                    <span className='span1'>Explore a comprehensive suite of solutions for your business evolution.</span>
                    <span className='span2'>[Quality . Goodness . Trust]</span>
                    <Link to="/contact" key='1' className='button'>
                        <div className="button1"></div><div className="text">Get In Touch →</div>
                   </Link>
                </div>
                
                <div className="image">
                    <div className="logoss">
                        <div className="logo1">
                            <img src={logo1} alt='logo1'/>
                        </div>
                        <div className="logo2">
                            <img src={logo2} alt='logo1'/>
                        </div>
                    </div>
                    
                    {/* <div className="contact">,
                        <div className="card">
                            <div className="mail">
                                <span>mail@qbsllp.in</span>
                            </div>
                            <div className="phone">
                                <span>+919446122733</span>
                            </div>
                        </div>
                    </div> */}
                    {/* <img src={img} alt='home-img'/> */}
                </div>
            </div>
        </>
    )
}


export default HomeSection1;