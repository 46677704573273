import Navbar from "../innerComponents/Navbar";
import '../styles/Conditions.css';

function Refund(){
    return(
        <>
            <div className='terms'>
                <Navbar/>
                <div className="mainhead">
                    <span>Cancellation & Refund Policy</span>
                </div>
                <div className="subhead">
                    <span>Last updated on 27-03-2024 20:21:38</span>
                </div>
                <div className="content">
                    <span>QIYAM BUSINESS SOLUTIONS LLP believes in helping its customers as far as possible, and has 
therefore a liberal cancellation policy. Under this policy:</span>
                </div>
                <div className="points">
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Cancellations will be considered only if the request is made immediately after placing the order. 
However, the cancellation request may not be entertained if the orders have been communicated to the 
vendors/merchants and they have initiated the process of shipping them.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>QIYAM BUSINESS SOLUTIONS LLP does not accept cancellation requests for perishable items like 
flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the 
quality of product delivered is not good.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>In case of receipt of damaged or defective items please report the same to our Customer Service team. 
The request will, however, be entertained once the merchant has checked and determined the same at his 
own end. This should be reported within 30 Days days of receipt of the products. In case you feel that the 
product received is not as shown on the site or as per your expectations, you must bring it to the notice of 
our customer service within 30 Days days of receiving the product. The Customer Service Team after 
looking into your complaint will take an appropriate decision.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>In case of complaints regarding products that come with a warranty from manufacturers, please refer 
the issue to them. In case of any Refunds approved by the QIYAM BUSINESS SOLUTIONS LLP, it’ll 
take 16-30 Days days for the refund to be processed to the end customer.</span></div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Refund;