import '../styles/PortfolioSection.css';
import PortfolioSwiper from './PortfolioSwiper';
import { portfolios } from '../data/portfolios';

function PortfolioSection(){
    return(
        <>
            <div className="portfoliosection">
                <div className="text">
                    <div className='span'><span>Portfolio</span></div>
                    <PortfolioSwiper portfolios={portfolios}/>
                </div>
            </div>
        </>
    )
}
export default PortfolioSection;