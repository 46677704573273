import { Link } from 'react-router-dom';
import img from '../assets/servicelogo/credit.png';
import '../styles/CreditSection.css';

function CreditSection(){
    return(
        <>
            <Link to='/services/credit-health-improvement/credit-health-improvement' className='credit-section'>
                <div className='quote'>
                    <div className='hd1main'><span className='hd1'>Credit Health Improvement</span></div>
                    <div className='hd1main'><span className='hd2'>Our most in-demand services</span></div>
                    <span className='txt'>“As experts in Credit Health Improvement, our mission is to guide individuals toward financial well-being. Led by certified Credit Experts, we analyze credit reports, identify issues, and develop personalized plans for improvement—going beyond scores to enhance overall credit health.”</span>
                    <Link to="/pricing-plan" key='1' className='button'>
                        <div className="button1"></div><div className="text">Choose Your Pricing Plan →</div>
                   </Link>
                </div>
                <div className="image">
                    <img src={img} alt='credit-img'/>
                </div>
            </Link>
        </>
    )
}

export default CreditSection;