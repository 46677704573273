import { Link } from "react-router-dom";
import '../styles/Services.css';
import ProjectCollaboration from "./ProjectCollaboration";

function AllServices({services,category}){
    return(
        category==='all'?
        <>
            <div className="services">
                <div className="heading"><span>Our Services</span></div>
                <div className="section">
                    <div className="quote">
                        <span className="ourservice">Our Services</span>
                        <span className="text">Giving Your Business Some Great Ideas</span>
                        <Link to="/contact" key='1' className='button'>
                            <div className="button1"></div><div className="text">Get In Touch →</div>
                        </Link>
                    </div>
                    <div className="list">
                        {services.map((item, index) => (
                            <Link className="service" to={item.route} key={index}>
                                <div className="serviceheading"><span>{item.name}</span></div>
                                <div className="servicecontent">{item.description}{item.category}{item.route}</div>
                            </Link>
                         ))}
                    </div>
                </div>
                <div style={{borderBottom:'2px solid #FFFFEF',margin:"30px"}}></div>
                <ProjectCollaboration/>              
            </div>
        </>
        :
        <>
            <div className="services">
                <div className="heading"><span>Our Services</span></div>
                <div className="section">
                    <div className="quote">
                        <span className="ourservice">Our Services</span>
                        <span className="text">Giving Your Business Some Great Ideas</span>
                        <Link to="/contact" key='1' className='button'>
                            <div className="button1"></div><div className="text">Get In Touch →</div>
                        </Link>
                    </div>
                    <div className="list">
                        {services.map((item, index) => (
                            item.category===category?
                            <Link className="service" to={item.route} key={index}>
                                <div className="serviceheading"><span>{item.name}</span></div>
                                <div className="servicecontent">{item.description}</div>
                            </Link>
                            :
                            <></>
                        ))}
                    </div>
                </div>  
                <div style={{borderBottom:'2px solid #FFFFEF'}}></div>
                <ProjectCollaboration/>            
            </div>

            
        </>
    )
}
export default AllServices;