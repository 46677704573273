import React from 'react';
import '../styles/Footer.css';
import logo from '../assets/logo.png';
import { FaFacebook,FaInstagram,FaLinkedin,FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="sec1">
        <div className="child-sec"><img src={logo} alt='logo'/></div>
        <div className="child-sec">
          <div className='heading'><span>Quick Links</span></div>
          <Link to='/' className='link'><span>Home</span></Link>
          <Link to='/about' className='link'><span>About</span></Link>
          <Link to='/services' className='link'><span>Services</span></Link>
          <Link to='/contact' className='link'><span>Contact</span></Link>
        </div>
        <div className="child-sec">
          <div className='heading'><span>Contacts</span></div>
          <div className='link'><span>Qiyam Business Solutions LLP, Regd Office adress. 1/126, Kozhikode, 673323</span></div>
          <div className='link'><span>mail@qbsllp.in</span></div>
          <div className='link'><span>+91 9446122733</span></div>
        </div>
        <div className="child-sec">
          <div className='heading'><span>Follow Us</span></div>
          <div className='icons'>
            <Link to='https://www.facebook.com/share/bBigLmAdpR4YMtdi/?mibextid=qi2Omg' className="icon fb"><FaFacebook/></Link>
            <Link to='https://www.instagram.com/qbsllp?igsh=eXVmc2E2bnE5M3R3' className="icon insta"><FaInstagram/></Link>
            <Link to='https://in.linkedin.com/in/qiyam-business-solutions-225170301' className="icon linkedin"><FaLinkedin/></Link>
            <Link to='https://youtube.com/@qbsllp?si=oeVyyNPHGy5znjht' className="icon utb"><FaYoutube/></Link>
          </div>
        </div>
      </div>
      <hr/>
      <div className='sec2'>
        <span>Copyright © 2024 qbsllp</span>
        <Link className='tlink' to='/terms-and-conditions'>Terms and Conditions</Link>
        <Link className='tlink' to='/privacy-policy'>Privacy Policy</Link>
        <Link className='tlink' to='/cancellation-and-refund-policy'>Cancellation & Refund Policy</Link>
      </div>
    </footer>
  );
}

export default Footer;
