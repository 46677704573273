import '../styles/Portfolio.css';
import arr from '../assets/arrow_infobox.png';
import Navbar from '../innerComponents/Navbar';


function ImagePortfolio({portfolios}){
    return(
        <>
            <div className="portfolio">
                <Navbar/>
                <div className="head">
                    <span>Image Portfolio</span>
                </div>
                <div className="content"> 
                {portfolios.map((item, index) => (
                    <div className="card" key={index}>
                        <div className="img"><img src={item} alt="portfolio"/></div>
                        <div className='arrow'><img src={arr} alt='service'/></div>
                    </div>
                ))}
                </div>
            </div>
        </>
    )
}

export default ImagePortfolio;