import Navbar from "../innerComponents/Navbar";
import '../styles/Conditions.css';

function Privacy(){
    return(
        <>
            <div className='terms'>
                <Navbar/>
                <div className="mainhead">
                    <span>Privacy Policy</span>
                </div>
                <div className="subhead">
                    <span>Last updated on 27-03-2024 20:20:52</span>
                </div>
                <div className="content">
                    <span>This privacy policy (“Policy”) relates to the manner QIYAM BUSINESS SOLUTIONS LLP (“we”, 
“us”, “our”) in which we use, handle and process the data that you provide us in connection with using 
the products or services we offer. By using this website or by availing goods or services offered by us, 
you agree to the terms and conditions of this Policy, and consent to our use, storage, disclosure, and 
transfer of your information or data in the manner described in this Policy.</span>
                    <span>We are committed to ensuring that your privacy is protected in accordance with applicable laws and 
regulations. We urge you to acquaint yourself with this Policy to familiarize yourself with the manner in 
which your data is being handled by us.</span>
                    <span>QIYAM BUSINESS SOLUTIONS LLP may change this Policy periodically and we urge you to check 
this page for the latest version of the Policy in order to keep yourself updated.</span>
                </div>
                <div className="subhead">
                    <span>What data is being collected</span>
                </div>
                <div className="content">
                    <span>We may collect the following information from you:</span>
                </div>
                <div className="points">
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Name</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Contact information including address and email address</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Demographic information or, preferences or interests</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Personal Data or Other information relevant/ required for providing the goods or services to you</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>The meaning of Personal Data will be as defined under relevant Indian laws</span></div>
                    </div>
                </div>
                <div className="content">
                    <span><span style={{fontWeight:'bold'}}>Note:</span> Notwithstanding anything under this Policy as required under applicable Indian laws, we will not 
be storing any credit card, debit card or any other similar card data of yours. Please also note that all data 
or information collected from you will be strictly in accordance with applicable laws and guidelines.</span>
                </div>
                <div className="subhead">
                    <span>What we do with the data we gather</span>
                </div>
                <div className="content">
                    <span>We require this data to provide you with the goods or services offered by us including but not limited, for 
the below set out purposes:</span>
                </div>
                <div className="points">
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Internal record keeping.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>For improving our products or services.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>For providing updates to you regarding our products or services including any special offers.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>To communicate information to you</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>For internal training and quality assurance purposes</span></div>
                    </div>
                </div>
                <div className="subhead"><span>Who do we share your data with</span></div>
                <div className="content"><span>We may share your information or data with:</span></div>
                <div className="points">
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Third parties including our service providers in order to facilitate the provisions of goods or 
services to you, carry out your requests, respond to your queries, fulfil your orders or for other 
operational and business reasons.</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>With our group companies (to the extent relevant)</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Our auditors or advisors to the extent required by them for performing their services</span></div>
                    </div>
                    <div className="point">
                        <div className="dotcontainer"><div className="dot"></div></div>
                        <div className="text"><span>Governmental bodies, regulatory authorities, law enforcement authorities pursuant to our legal 
obligations or compliance requirements.</span></div>
                    </div>
                    <div className="subhead"><span>How we use cookies</span></div>
                    <div className="content">
                        <span>We use "cookies" to collect information and to better understand customer behaviour. You can instruct 
your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not 
accept cookies, you may not be able to avail our goods or services to the full extent. We do not control 
the use of cookies by third parties. The third party service providers have their own privacy policies 
addressing how they use such information.
</span>
                    </div>
                    <div className="subhead"><span>Your rights relating to your data</span></div>
                    <div className="content">
                        <span><span style={{fontWeight:'bold',fontStyle:'italic',fontFamily:'Roman',fontSize:'18px'}}>Right to Review -</span> You can review the data provided by you and can request us to correct or amend such 
data (to the extent feasible, as determined by us). That said, we will not be responsible for the 
authenticity of the data or information provided by you.</span>
                        <span><span style={{fontWeight:'bold',fontStyle:'italic',fontFamily:'Roman',fontSize:'18px'}}>Withdrawal of your Consent -</span> You can choose not to provide your data, at any time while availing our 
goods or services or otherwise withdraw your consent provided to us earlier, in writing to our email ID: 
mail@qbsllp.inIn the event you choose to not provide or later withdraw your consent, we may not be 
able to provide you our services or goods.Please note that these rights are subject to our compliance with 
applicable laws.</span>
                    </div>
                    <div className="subhead"><span>How long will we retain your information or data?</span></div>
                    <div className="content">
                        <span>We may retain your information or data (i) for as long as we are providing goods and services to you; 
and (ii) as permitted under applicable law, we may also retain your data or information even after you 
terminate the business relationship with us. However, we will process such information or data in 
accordance with applicable laws and this Policy.
</span>
                    </div>
                    <div className="subhead"><span>Data Secuirity</span></div>
                    <div className="content">
                        <span>We will use commercially reasonable and legally required precautions to preserve the integrity and 
security of your information and data.</span>
                    </div>
                    <div className="subhead"><span>Queries/ Grievance Officer</span></div>
                    <div className="content"><span>For any queries, questions or grievances about this Policy, please contact us using the contact 
information provided on this website.</span></div>
                </div>
            </div>
        </>
    )
}


export default Privacy;