import React, { useState, useEffect } from 'react';
import '../styles/HomeSection2.css';

function HomeSection2(){

    const [clientCounter, setclientCounter] = useState(0);
    const [projectCounter, setProjectCounter] = useState(0);
    const [accoladeCounter, setAccoladeCounter] = useState(0);
    const [codelineCounter, setCodelineCounter] = useState(0);

    useEffect(() => {
        const updateClientNumber = () => {
            if (clientCounter < 87) {
                setclientCounter(prevclientCounter => prevclientCounter + 1);
            } else {
                clearInterval(intervalId);
            }
        };
        const intervalId = setInterval(updateClientNumber, 75);
        return () => clearInterval(intervalId);
    }, [clientCounter]);

    useEffect(() => {
        const updateProjectNumber = () => {
            if (projectCounter < 150) {
                setProjectCounter(prevclientCounter => prevclientCounter + 1);
            } else {
                clearInterval(intervalId);
            }
        };
        const intervalId = setInterval(updateProjectNumber, 35);
        return () => clearInterval(intervalId);
    }, [projectCounter]);

    useEffect(() => {
        const updateAccoladeNumber = () => {
            if (accoladeCounter < 28) {
                setAccoladeCounter(prevclientCounter => prevclientCounter + 1);
            } else {
                clearInterval(intervalId);
            }
        };
        const intervalId = setInterval(updateAccoladeNumber, 230);
        return () => clearInterval(intervalId);
    }, [accoladeCounter]);

    useEffect(() => {
        const updateCodelineNumber = () => {
            if (codelineCounter < 56) {
                setCodelineCounter(prevclientCounter => prevclientCounter + 1);
            } else {
                clearInterval(intervalId);
            }
        };
        const intervalId = setInterval(updateCodelineNumber, 130);
        return () => clearInterval(intervalId);
    }, [codelineCounter]);

    return(
        <>
            <div className='home-section2'>
                <div className='card'>
                    <div className="clients">
                        <span className='count'>{clientCounter}+</span>
                        <span className='text'>Satisfied<br/>Clients</span>
                        {/* <img src={clients} alt='clients'/> */}
                    </div>
                    <div className="vertical-line"></div>
                    <div className="projects">
                        <span className='count'>{projectCounter}</span>
                        <span className='text'>Projects<br/>Completed</span>
                        {/* <img src={projects} alt='projects'/> */}
                    </div>
                    <div className="vertical-line"></div>
                    <div className="accolades">
                        <span className='count'>{accoladeCounter}</span>
                        <span className='text'>Accolades<br/>Earned</span>
                        {/* <img src={accolade} alt='accolade'/> */}
                    </div>
                    <div className="vertical-line"></div>
                    <div className="lineofcode">
                        <span className='count'>{codelineCounter}K+</span>
                        <span className='text'>Lines of<br/>Code</span>
                        {/* <img src={code} alt='code'/> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeSection2;