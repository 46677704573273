import '../styles/Portfolio.css';
import ReactPlayer from 'react-player/youtube'
import Navbar from '../innerComponents/Navbar';

function VideoPortfolio(){
    return(
        <>
            <div className="portfolio">
                <Navbar/>
                <div className="head">
                    <span>Video Portfolio</span>
                </div>
                <div className="content"> 
                    <ReactPlayer width="300px" height="220px" url='https://youtube.com/shorts/9tgsgSzdv4E?feature=share' />
                    <ReactPlayer width="300px" height="220px" url='https://youtube.com/shorts/32-Onnsz90k?feature=share' />
                    <ReactPlayer width="300px" height="220px" url='https://youtube.com/shorts/g-zq4qCNSpU?feature=share' />
                </div>
            </div>
        </>
    )
}

export default VideoPortfolio;