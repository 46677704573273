import Navbar from '../innerComponents/Navbar';
import '../styles/JoinUs.css'
import { FaFacebook,FaInstagram,FaLinkedin,FaYoutube } from 'react-icons/fa';
import React from 'react';
import { Link } from 'react-router-dom';

function JoinUs(){
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "617f87ff-6101-473c-bcac-1d8c02d184a6");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          event.target.reset();
        } else {
          console.log("Error", data);
        }
      };
    return(
        <>
            <div className='body'>
                <Navbar/>
                <div className="title">
                    <span>Join Us</span>
                </div>
                <div className='joinuscontainer'>
                    <div className="row">
                        <section className="col right">
                            <form onSubmit={onSubmit} className="messageForm">
                                <div className="inputGroup fullWidth">
                                    <span className='drop'>Join Our Team</span>
                                </div>
                                <div className="inputGroup halfWidth" style={{display:'none'}}>
                                    <input type="text" name="JobRequest" value="Job Request"/>
                                    <label>Job Request</label>
                                </div>
                                <div className="inputGroup halfWidth">
                                    <input type="text" name="FirstName" required="required"/>
                                    <label>First Name</label>
                                </div>
                                <div className="inputGroup fullWidth" style={{display:'none'}}>
                                    <input type="text" name=""/>
                                    <label>None</label>
                                </div>
                                <div className="inputGroup halfWidth">
                                    <input type="text" name="LastName" required="required"/>
                                    <label>Last Name</label>
                                </div>
                                
                                <div className="inputGroup fullWidth">
                                    <input type="text" name="Place" required="required"/>
                                    <label>Place</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="text" id="position" list="positions" name='position' required="required"/>
                                    <datalist id="positions">
                                        <option value="Graphic Designer"/>
                                        <option value="Mobile App Developer"/>
                                        <option value="Web App Developer"/>
                                        <option value="Content Presenter"/>
                                        <option value="Content Writer"/>
                                        <option value="Sales Executive"/>
                                        <option value="Buisiness Development Executive"/>
                                        <option value="Product Designer"/>
                                        <option value="Video Editor"/>
                                    </datalist>
                                    <label for='position'>Position Applied For</label>
                                </div>
                                <div className="inputGroup halfWidth">
                                    <input type="text" name="Experience" required="required"/>
                                    <label>Experience in Years</label>
                                </div>
                                <div className="inputGroup halfWidth">
                                    <input type="text" name="Phone" required="required"/>
                                    <label>Contact Number</label>
                                </div>
                                <div className="inputGroup fullWidth">
                                    <input type="email" name="Email" required="required"/>
                                    <label>Email</label>
                                </div>
                                <div className="inputGroup fullWidth">                                    
                                    <textarea required="required"></textarea>
                                    <label>Additional Information</label>
                                </div>
                                {/* <div className="inputGroup halfWidth">
                                    <input type="file" name="Resume" className='file-name' required="required"/>
                                    <label>Upload Resume</label>
                                </div>
                                <div className="inputGroup halfWidth">
                                    <input type="file" name="Portfolio" className='file-name'/>
                                    <label for='file'>Upload Portfolio</label>
                                </div> */}
                                <button type='submit' className='button'>
                                    <div className="button1"></div><div className="text">Apply Now →</div>
                                </button>
                            </form>
                        </section>                           
                    </div>
                    <section>
                        <div className="contactsection">
                            <span className='abt'>Our contacts</span>
                            <span className='hd'>Let’s Start Working Together. Get in Touch with Us!</span>
                            <span className='txt'>Get in touch to discuss your employee wellbeing needs today. Please give us a call, drop us an email.</span>
                            <div className="addressandsocial">
                                <div className="place"><span>Qiyam Business Solutions LLP, Regd Office adress. 1/126, Kozhikode, 673323</span></div>
                                <div className="phone"><span>+91 9446122733</span></div>
                                <div className="site"><span>www.qbsllp.in</span></div>
                                <div className="social">
                                    <Link to='https://www.facebook.com/share/bBigLmAdpR4YMtdi/?mibextid=qi2Omg' className="icon fb"><FaFacebook/></Link>
                                    <Link to='https://www.instagram.com/qbsllp?igsh=eXVmc2E2bnE5M3R3' className="icon insta"><FaInstagram/></Link>
                                    <Link to='https://in.linkedin.com/in/qiyam-business-solutions-225170301' className="icon linkedin"><FaLinkedin/></Link>
                                    <Link to='https://youtube.com/@qbsllp?si=oeVyyNPHGy5znjht' className="icon utb"><FaYoutube/></Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>   
        </>
    )
}
export default JoinUs;