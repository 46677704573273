import '../styles/Whatsapp.css';
import icon from '../assets/wapp.png';
import { Link } from 'react-router-dom';

function Whatsapp(){
    return(
        <div className="whatsapp">
            <Link to='https://wa.me/919446122733' className="btn">
                <img src={icon} alt='whatsapp'/>
                </Link>
        </div>
    )
}
export default Whatsapp;