import { Link } from 'react-router-dom';
import '../styles/GetInTouch.css'

function GetInTouch(){
    return(
        <>
            <div className="getin">
                <div className="getintext">
                    <span>Get in </span><span className='touch'>Touch</span>
                </div>
                <div className="getinbutton">
                    <Link to="/contact" key='1' className='button'>
                        <div className="button1"></div><div className="text">SEND US A MESSAGE →</div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default GetInTouch;