import { Link } from 'react-router-dom';
import '../styles/GetInTouch.css'

function ProjectCollaboration(){
    return(
        <>
            <div className="collab">
                <div className="collabtext">
                    <span>Collaborate on a Project</span>
                </div>
                <div className="collabbutton">
                    <Link to="https://wa.me/919446122733" key='1' className='button'>
                        <div className="button1"></div><div className="text">Get A Quote→</div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ProjectCollaboration;